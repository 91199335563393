import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.gestures.scrollBy
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableSharedFlow
import model.Screen
import model.UiDirection
import model.fromPath
import org.jetbrains.skiko.loadBytesFromPath
import se.sekvy.compose.markdown.MDDocument
import se.sekvy.compose.markdown.MarkdownParser
import se.sekvy.compose.markdown.NodeType
import se.sekvy.compose.markdown.ParserType
import theme.HomePageTheme
import theme.colorBackgroundDark
import ui.CustomScaffold
import ui.Menu

const val hashSlashFix: Boolean = true

fun main() {
    val onSize = MutableSharedFlow<IntOffset>(extraBufferCapacity = 1, replay = 1)
    val onScroll = MutableSharedFlow<Double>(extraBufferCapacity = 1, replay = 1)
    val onHashChange = MutableSharedFlow<String>(extraBufferCapacity = 1, replay = 1)
    renderInComposeWindow(
        backgroundColor = colorBackgroundDark,
        onNewWindowSize = { width, height ->
            onSize.tryEmit(IntOffset(width, height))
        },
        onScroll = {
            onScroll.tryEmit(it)
        },
        onHashChange = {
            onHashChange.tryEmit(it)
        }
    ) {
        HomePageTheme {
            val uiDirection = onSize.collectAsState(IntOffset(0, 0)).toUIDirection()
            Content(
                uiDirection = uiDirection,
                onScroll = onScroll,
                onHashChange = onHashChange
            )
        }
    }
}

@Composable
fun Content(uiDirection: UiDirection, onScroll: MutableSharedFlow<Double>, onHashChange: MutableSharedFlow<String>) {
    var showContent by remember { mutableStateOf(false) }

    var centerScreen by remember {
        mutableStateOf<Screen>(HomeScreen(""))
    }

    LaunchedEffect(Unit) {
        snapshotFlow { centerScreen }.collect {
            console.info(
                "centerScreen: setting hash to ${it.path}"
            )
            val newHash = if (hashSlashFix) it.path.replace("/", "|") else it.path
            if (newHash.isNotEmpty()) {
                window.location.hash = newHash.lowercase()
            }
        }
    }

    LaunchedEffect(Unit) {
        onHashChange.collect {
            val screen = fromPath(paths, if (hashSlashFix) it.replace("|", "/") else it)
            when (screen) {
                is HomeScreen -> {
                    showContent = true
                }
                null -> {}
                else -> {
                    showContent = true
                }
            }
            if (screen != null) centerScreen = screen
        }
    }

    CustomScaffold(
        uiDirection = uiDirection,
        startContent = {
            if (uiDirection == UiDirection.Horizontal) {
                MenuContent(
                    Modifier,
                    uiDirection,
                ) {
                    centerScreen = it
                }
            }
        },
        centerContent = {
            val scrollState = rememberScrollState()

            LaunchedEffect(Unit) {
                onScroll.collect {
                    scrollState.scrollBy(it.toFloat())
                }
            }

            Box(
                modifier = Modifier.matchParentSize().verticalScroll(scrollState),
                contentAlignment = Alignment.Center
            ) {
                AnimatedVisibility(
                    visible = centerScreen is HomeScreen,
                    enter = fadeIn(animationSpec = tween(1000)),
                    exit = fadeOut(),
                    content = {
                    }
                )
                AnimatedVisibility(
                    visible = centerScreen is PrivacyScreen,
                    enter = fadeIn(animationSpec = tween(1000)),
                    exit = fadeOut(),
                    content = {
                        showArticle("privacy.md", uiDirection)
                    }
                )
                AnimatedVisibility(
                    visible = centerScreen is TermsScreen,
                    enter = fadeIn(animationSpec = tween(1000)),
                    exit = fadeOut(),
                    content = {
                        showArticle("terms.md", uiDirection)
                    }
                )
            }
        },
        endContent = {
            if (uiDirection == UiDirection.Vertical) {
                MenuContent(
                    Modifier,
                    uiDirection,
                ) {
                    centerScreen = it
                }
            }
        },
        overlay = {
        }
    )
}

@Composable
fun MenuContent(
    modifier: Modifier,
    uiDirection: UiDirection,
    onSelected: (Screen) -> Unit
) {
    Box(modifier) {
        Menu(
            direction = uiDirection,
            onHomeClick = {
                onSelected(HomeScreen(HomePath().pathPattern))
            },
            onTermsClick = {
                onSelected(TermsScreen(TermsPath().pathPattern))
            },
            onPrivacyClick = {
                onSelected(PrivacyScreen(PrivacyPath().pathPattern))
            },
        )
    }
}

@Composable
fun showArticle(path: String, uiDirection: UiDirection) {
    var nodeType: NodeType? by remember { mutableStateOf(null) }

    LaunchedEffect(path) {
        val bytes = loadBytesFromPath(path)
        nodeType = MarkdownParser(ParserType.CommonMark).parse(bytes.decodeToString())
    }

    nodeType?.let {
        val padding = if (uiDirection == UiDirection.Vertical) {
            24.dp
        } else {
            88.dp
        }
        Box(modifier = Modifier.padding(start = padding, end = padding, top = 24.dp, bottom = 24.dp)) {
            Column {
                MDDocument(it) {
                    loadBitmap(it)
                }
            }
        }
    }
}
