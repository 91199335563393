import model.Path
import model.Screen

class HomeScreen(override val path: String) : Screen

class PrivacyScreen(override val path: String) : Screen

class TermsScreen(override val path: String) : Screen

class HomePath : Path<HomeScreen> {
    override val pathPattern: String = "home"

    override fun complete(path: String) = HomeScreen(path)
}

class PrivacyPath : Path<PrivacyScreen> {
    override val pathPattern: String = "privacy"

    override fun complete(path: String) = PrivacyScreen(path)
}

class TermsPath : Path<TermsScreen> {
    override val pathPattern: String = "terms"

    override fun complete(path: String) = TermsScreen(path)
}

val paths = listOf(
    HomePath(),
    PrivacyPath(),
    TermsPath(),
)
